

import {Link} from 'react-router-dom';

const MainHeader = () => (
<div className="main_cont_routs">
<Link to="/" className='main_header_a' id="main_go_to_main">DOBBIKOV</Link>
<Link to="/about_me" className='main_header_a'>ABOUT ME</Link>
<Link to="/lecture_notes" className='main_header_a'>LECTURE NOTES</Link>
<Link to="/contact_me" className='main_header_a'>CONTACT ME</Link>
</div>
)

export default MainHeader;