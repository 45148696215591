import MainHeader from "./MainHeader";


const Header = () => (
    <div className='header_main_cont'>
        <div className='header_main_cont_inner'>
            <MainHeader/>
        </div>
    </div>
)
export default Header;